<template>
  <div></div>
</template>
<script>
import { EventBus } from '../main'
import stickyHeader from '../mixins/stickyHeader'
import cloneHeader from '../mixins/cloneHeader'

export default {
  data() {
    return {
      inlineEdit: false,
      scrollbarWidth: 0,
      reRender: true,
      storedAndValidated: '',
      compare_storedAndValidated: '',
      rowsPerPage: 10 // default value
    }
  },
  mounted() {
    // * this reads the current value on Rows Per Page Selector
    let select = document.querySelector("#app > div.vgt-wrap > div > div.vgt-wrap__footer.vgt-clearfix > div.footer__row-count.vgt-pull-left > select")
    this.rowsPerPage = parseInt(select.value)

    // turn on/off inline-edit tables native functionallity | reason: blacklisted words have their own inline-edit logic
    EventBus.$on('inlineEditEvent', (bool) => {
      this.inlineEdit = bool
    })

    /**
     * * these methods are now called asyncronously from App.vue after loading table data
     * * this means that these methods will load only ONCE!!!
     * * that is, on async-then event
     */
    EventBus.$on('async-then', () => {
      setTimeout(() => {

      // // set sortable icons to hidden visibility
      // const tableRow = document.querySelector('tr');
      // // [...tableRow.children].map(i => console.log(i))
      // [...tableRow.children].map((i) => {
      //   // i.dataset.visibility = 'hidden'
      //   console.dir(i)
      // })

        // param mandatory, entered either as #idName, or .className, or <tag-name>
        this.resizeMe("table")
        this.delegateHover("table")
        this.validate("table")
      }, 0)
    })

    // instead forcing cmp to reload, react to event and reload certain methods
    EventBus.$on('verticalScroll', (rpp) => {
      setTimeout(() => {

        // param mandatory, entered either as #idName, or .className, or <tag-name>
        this.resizeMe("table", rpp) // <- rpp Rows Per Page
        this.rowsPerPage = parseInt(select.value)
        // this.delegateHover("table")
        // this.validate("table")
      }, 0)
    })
  },
  mixins: [
    stickyHeader('.vgt-table tr'),
    cloneHeader('th')
  ],
  methods: {
    first_clmn(table) {
      
      // * detect which column is first at first load and after Selector uncheck
      let allThs = table.querySelectorAll('th');
      let firstTh = [...allThs].filter(e => !e.hasAttribute('hidden'))
      let firstTdColumn = table.querySelectorAll(`td:nth-child(${firstTh[0].cellIndex + 1})`)
      firstTh[0].style.position = 'sticky'
      firstTh[0].style.left = 0
      firstTh[0].style.zIndex = 1
      Array.from(firstTdColumn).map((fTd, index) => {
        fTd.style.position = 'sticky'
        fTd.style.left = 0

        // colorize first column on load
        this.colorize(index, fTd)
      })
    },
    colorize(i,f) {
      f.style.background = i%2 == 0 ? '#EFEFEF' : 'white'
      // i%2 == 0 ? f.style.background = '#EFEFEF' : f.style.background = 'white'
    },
    resizeMe(element, rpp) {
      /*eslint-disable*/
      const table = document.querySelector(element)

      // ! adds data attributes on load (1, 2, 3, 4...)
      const header = table.querySelectorAll('th')
      let sorting = {};
      [...header].map((th, index) => {
        Object.assign(sorting, {[th.innerText]: 'gray'})
        if(!rpp) { // ! this scenario is meant to happen' only on first load
          th.setAttribute('data-order', index + 1);
          [...table.querySelectorAll(`td:nth-child(${th.cellIndex + 1})`)].map(td => td.setAttribute('data-order', index + 1))
        } else {

          // * skips first 10 rows (i >= this.rowsPerPage /*initial (10)*/), so they keep they previous order attributes, and add new order to rest of the rows (newly loaded)
          [...table.querySelectorAll(`td:nth-child(${th.cellIndex + 1})`)].map((td, i) => i >= this.rowsPerPage /*initial (10)*/ && td.setAttribute('data-order', index + 1))
        }
      })
      localStorage.setItem('sorting', JSON.stringify(sorting))

      table.classList.add('brdr-btm')

      // * FIRST COLUMN STICKY POSITION

      EventBus.$on('first_clmn', () => {

        // * remove all sticky positions and z-index from all th
        [...table.querySelectorAll('th')].map(item => item.style.removeProperty('z-index'));
        [...table.querySelectorAll('th')].map(item => item.style.removeProperty('position'));
        
        // * remove all stycky positions from all td
        [...table.querySelectorAll('td')].map(item => item.style.removeProperty('position'))
        this.first_clmn(table)
      })

      this.first_clmn(table)

      // sticky header, not working due to parent element overflow
      // let ThRow = table.querySelectorAll('th')
      // Array.from(ThRow).map(th => {
      //   th.style.setProperty('position', 'sticky', 'important')
      //   th.style.setProperty('top', '0', 'important')
      // })
			
      // Query all headers
      const cols = table.querySelectorAll("th")
			let thWidths = []

      // Loop over headers
      cols.forEach.call(cols, (col) => {

        // stores TH widths to localStorage, but only if all the THs are shown (this cmp force reloads, so it is imperative to check widths on re-render event)
        EventBus.$on('re-render', (bool) => {
          this.reRender = bool
        })
        if(this.reRender) {
          thWidths.push(col.offsetWidth)
          localStorage.setItem('thW', JSON.stringify(thWidths))
        }

        // Create a resizer element
        const resizer = document.createElement("div")
        resizer.classList.add("resizer")

        // * set the height with -1 to match the height of the table (this will not recalculate the height of the line)
				resizer.style.height = `${table.offsetHeight - 1}px`

        // * reset the height of the resizer line
        /**
         * at the moment resizer does not update on CMP reload
         * it updates on RPP change event
         */
        EventBus.$on('verticalScroll', () => {
          setTimeout(() => {
            table.classList.add('brdr-btm')
            if(col.children[1]) // * col.children[0] == <span> | col.children[1] == resizer element
              if(col.children[1].className == 'resizer')
                col.children[1].style.height = table.offsetHeight - 1 + 'px'
          }, 0)
        })

        // add a resizer element to the column only if there is none
        // this will happend only once, as there is a key force update on this component which causes resizer multiplying on each call
        if(col.childElementCount === 1) // this 1 should be 0, but vue-good-table has span element inside so 1 then :)
          col.appendChild(resizer)
        let x = 0
        let w = 0

        // HAPPENING ON MOUSEDOWN
        const mouseDownHandler = (e) => { // ! from function() to () => ... whatchout for the bugs

          /**
         * logic to handle resize from fit-content to 0
         * innitial CSS sets max-width to fit-content on TD
         * so we need to capture size of clicked column,
         * then add a class that sets max-with to 0, enabling cells to size to ellipsis state
         * imediatelly on mousemove event, prevent auto resizing
         * of cell to ellipsis state by returning previously captured column size
         */

					let th = resizer.closest("th")

          // get the index of TH that holds clicked resizer DIV
          const parentIndex = Array.from(th.parentNode.children).indexOf(th)

          // get all td elements in the same table that have the same child index as resizer parrent
          let columnTds = table.querySelectorAll(`td:nth-child(${parentIndex + 1})`)
					const thWidth = th.clientWidth + 1 // border-box + this 1 pixel to get exact width in px, or use offsetWidth

          /**
           * adds a class mv0 to current column tds and removes it on dblclick
           * this restores cell width to default size
          */
          Array.from(columnTds).map((el) => {
            el.classList.add("mv0")

            resizer.ondblclick = () => {
              Array.from(columnTds).map((el) => {
                el.classList.remove("mv0")
              })

							// this restores th to innitial width on dbl click
							th.style.minWidth = `${thWidths[th.cellIndex]}px`
              th.style.width = `${thWidths[th.cellIndex]}px`
              
              if(el.cellIndex == localStorage.getItem('reszrCIndex'))
                localStorage.setItem('thW', JSON.stringify(thWidths))
            }

            // prevents accidental sorting which happens on click of a resizers parent TH cell
            resizer.onclick = (e) => {
              e.stopPropagation()
            }
            this.cloneHeader()
            this.stickyHeader()
          })

          // set initiall width
          col.style.width = `${thWidth}px`
          
          // Get the current mouse position
          x = e.clientX
          resizer.classList.add("resizing")

          // Calculate the current width of column
          const styles = window.getComputedStyle(col)
          w = parseInt(styles.width, 10)

          // Attach listeners for document's events
          document.addEventListener("mousemove", mouseMoveHandler)
          document.addEventListener("mouseup", mouseUpHandler)
        };

        // HAPPENING ON DRAGING AN EDGE
        const mouseMoveHandler = (e) => {
          let vgt = document.querySelector('.vgt-responsive')
          let firstColumn = table.rows[0].children[0].children[1]
          if(vgt.scrollWidth > vgt.clientWidth) {
            table.classList.remove('brdr-btm');
            firstColumn.classList.add('left-shadow') // ! not displaying shadow
          } else {
            table.classList.add('brdr-btm')
            firstColumn.classList.remove('left-shadow')
          }

          // Determine how far the mouse has been moved
          const dx = e.clientX - x

          // Update the width of column (if draging mouse to the right... or to the left...)
          if(dx > 0) {
            col.style.minWidth = `${w + dx}px`
          } else {
            col.style.minWidth = 'unset'
            col.style.width = `${w + dx}px`
            col.style.minWidth = `${w + dx}px`
          }

          // set current width state of all TH to local storage
          let thW = [];
          [...cols].map((th) => {
            thW.push(th.offsetWidth)
          })
          localStorage.setItem('thW', JSON.stringify(thW))
          localStorage.setItem('reszrCIndex', resizer.closest('TH').cellIndex)
          this.cloneHeader() // to instantly handles the width of a cloned header
          this.stickyHeader()
        }

        const mouseUpHandler = (e) => {

          //  stop an event from running by intercepting capturing phase and removing a listener
          window.addEventListener(
            'click',
            captureClick,
            true
          )
          function captureClick(e) {
            e.preventDefault()
            e.stopPropagation()
            // console.log('event captured and canceled')
            window.removeEventListener('click', captureClick, true)
          }

          // remove existing event listeners
          document.removeEventListener("mousemove", mouseMoveHandler)
          document.removeEventListener("mouseup", mouseUpHandler)
          resizer.classList.remove("resizing")

          // this.cloneHeader() // to update the cloned header width upon finished dragging
          // console.log('dragging finished')
        }
        resizer.addEventListener("mousedown", mouseDownHandler)
			})

			// * changes initial table width from 100% to 'unset' and sets all th width to start size
			table.style.width = 'unset';
			[...cols].map((th, index) => {

        // * resets th widths on reload (refresh) regardles of local storage
        // * to change to local storage widths, replace these two lines with the local storage data
				th.style.minWidth = `${thWidths[index]}px`
        th.style.width = `${thWidths[index]}px`
      })

      // * on page change event... recalculate width of each TH
      EventBus.$once('verticalScroll', () => {
        // if(!localStorage.getItem('thW')) {setTimeout(() => {
        setTimeout(() => {
          
          // ! document.documentElement should be the container of the table (vue-good-table) <- pay attetion on implementing

          let scrollable = this.isScrollable(document.documentElement).verticallyScrollable

          // if after rowsPerPage click, page is scrollable & previos state was without scroll
          if(scrollable && !localStorage.getItem('scrBar')) {
            localStorage.setItem('scrollbarW', window.innerWidth - document.documentElement.clientWidth)
            const scrollbarWidth = localStorage.getItem('scrollbarW')
            let widths = JSON.parse(localStorage.getItem('thW'));

            [...cols].map((th, index) => {
              th.style.minWidth = `${widths[index] - Math.ceil(scrollbarWidth / cols.length)}px`
              th.style.width = `${widths[index] - Math.ceil(scrollbarWidth / cols.length)}px`
            })
            localStorage.setItem('scrBar', true)
          }

          // if after rowsPerPage click, page is not scrollable & previous state was scrollable
          if(!scrollable && localStorage.getItem('scrBar')) {
            localStorage.removeItem('scrBar');
            const scrollbarWidth = localStorage.getItem('scrollbarW')
            let widths = JSON.parse(localStorage.getItem('thW'));
            [...cols].map((th, index) => {
              th.style.minWidth = `${widths[index] + Math.ceil(scrollbarWidth / cols.length)}px`
              th.style.width = `${widths[index] + Math.ceil(scrollbarWidth / cols.length)}px`
            })
            localStorage.removeItem('scrollbarW')
          }

          // ! this should be added to the table container, not the window
          // returns table header position from top on scroll
          window.addEventListener('scroll', () => this.stickyHeader())
          this.cloneHeader() // call clone header here to have it on rpp change and to adapt sizes to vertical scroll bar
          this.stickyHeader()
        }, 0)//}
      })
    },

    // create fading element on hover over ellipsis cell
    delegateHover(element) {

      // this line should be refactored to have separate data
      const table = document.querySelector(element)

      // track hover event and determine if ellipsis is active (overflow is present)
      table.onmouseover = (event) => {
        let th = ''
        // if(event.target.localName == 'span' || event.target.localName == 'div' || event.target.localName == 'img') {
        if(event.target.localName == 'span') {
          th = event.target.parentElement
        } else if(event.target.localName == 'img') {
          th = event.target.offsetParent
        } else if(event.target.localName == 'td' || event.target.localName == 'div') {
        } else {
          th = event.target
        }
        this.$emit('hover-over', th.innerText)
        let td = event.target.closest("td")

        // because closest() may return multiple elements, and we only want td, we say: if not td skip it! 
        if (!td) return

        // if for any reason td was not found skip it! (just a safety measure)
        if (!table.contains(td)) return

        // add hover style on column
        let column = table.querySelectorAll(`td:nth-child(${td.cellIndex + 1})`)
        
        // set darker column color on hover
        Array.from(column).map((cell, index) => {
        // cell.style.background = 'rgba(111, 111, 111, 0.11)' // this is transparent approach

          // even / odd background styling
          index%2 == 0 ? cell.style.background = '#E0E0E0' : cell.style.background = '#EFEFEF' // this simulates transparent approach (colors are solid)
          // cell.style.background = 'linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(84,9,121,0.25) 100%)'
          cell.style.transition = 'background 0.5s'
        })
        td.onmouseleave = (event) => {
          const target = event.target;
          if (target.nodeName === "TD" && !target.contains(event.relatedTarget)) {
            Array.from(column).map((cell, index) => {

              // colorize first column on mouseleave, on other columns previously set color gets removed
              cell.cellIndex == 0 ? this.colorize(index, cell) : cell.style.background = 'none'
            })
          }
        }

        // remove span element from DOM but keep inner text | reason: to not mess with the pop-up modal event
        if (td.childElementCount > 0) {
          let spanText = td.children[0].innerText
          td.children[0].remove()
          td.innerHTML = spanText
        }

        // if there is text that is overflown... show modal
        if (0 > td.clientWidth - td.scrollWidth) {

          // create modal div
          let modal = document.createElement("div")

          // add current td content to modal
          modal.innerHTML = td.innerHTML

          // get mouse position
          let xMouse = event.pageX
          let yMouse = event.pageY

          // style the modal
          modal.style.width = "fit-content"
          modal.style.padding = "10px"
          modal.style.borderRadius = "5px"
          modal.style.background = "white"
          modal.style.position = "absolute"
          modal.style.top = yMouse + "px"
          modal.style.left = xMouse + "px"
          modal.style.pointerEvents = "none"
          modal.style.opacity = "0"

          // add shadow
          modal.style.boxShadow = "1px 1px 7px 0px rgba(176, 176, 176, 1)"

          // append to body
          document.body.appendChild(modal)

          this.fadeIn(modal)

          // fade out element
          td.onmouseleave = () => {
            this.fadeOut(modal)
          }
        }
      }
      // let UID = {
      //   _current: 0,
      //   getNew: function(){
      //     this._current++
      //     return this._current
      //   }
      // }
      // HTMLElement.prototype.pseudoStyle = function(element, prop, value) {
      //   let _this = this
      //   let _sheetId = "pseudoStyles"
      //   let _head = document.head || document.getElementsByTagName('head')[0]
      //   let _sheet = document.getElementById(_sheetId) || document.createElement('style')
      //   _sheet.id = _sheetId
      //   var className = "pseudoStyle" + UID.getNew()
        
      //   _this.className +=  " "+className;
        
      //   _sheet.innerHTML += " ."+className+":"+element+"{"+prop+":"+value+"}"
      //   _head.appendChild(_sheet)
      //   return this
      // }

      // table.onmouseover = (event) => {
        // // hovering over th cell to reveal sorting icon
        // let th = event.target.closest("th")
        // if(!th) return
        // if(!table.contains(th)) return
        // th.pseudoStyle("before", "visibility", "visible!important")
        // th.pseudoStyle("after", "visibility", "visible!important")
      // }
    },
    fadeIn(el) {
      let steps = 0
      let timer = setInterval(function () {
        steps++
        el.style.opacity = 0.1 * steps
        if (steps >= 10) {
          clearInterval(timer)
          timer = undefined
        }
      }, 20)
    },
    fadeOut(el) {
      let steps = 10
      let timer = setInterval(function () {
        steps--
        el.style.opacity = 0.1 * steps
        if (steps === 0) {
          clearInterval(timer)
          timer = undefined

          // remove modal from DOM
          el.remove()
        }
      }, 10)
    },
    validate(element) {
      let active_cell = null
      let active_cell_content = null
      const table = document.querySelector(element)

      // console.log('inline edit', this.inlineEdit)

      table.onmousedown = (event) => {

        // * only when clicked on TD, enable inline edit (this sets innerText on every change, so to not have problems with TH cells it has to be inside if statement) 
        if(event.target.tagName === 'TD') {
          active_cell = event.target
          active_cell_content = event.target.innerText
          active_cell.setAttribute('contenteditable', true)

          // this enables cell to be selected and focused on first click. Otherwise, cell focus would work only on second click
          active_cell.focus()
        }
      }

      /*eslint-disable*/
      table.onkeydown = (event) => {
        if(event.keyCode === 13) {
          return false
        }
      }
      table.oninput = (event) => {
        let text_i = event.target
        // const regx = /^[\w ]+$/
        const regx = /^[\w @.#-]+$/ // changed regex not to frbid email or #hash
        this.storedAndValidated = regx.test(text_i.innerText) && text_i.innerText
        // this.storedAndValidated = text_i.innerText

        // * forbiden chars imediate deletition
        // if(!regx.test(text_i.innerText))
        //   event.target.innerText = active_cell_content

          // // select all the content in the element
          // document.execCommand('selectAll', false, null);

          // // collapse selection to the end | moving caret to end
          // document.getSelection().collapseToEnd();

        // saves to store if ENTER is pressed | compare_storedAndValidated ensures that this happens only on change, so only once
        table.onkeydown = (event) => {
          if(event.keyCode === 13) {
            if(this.compare_storedAndValidated !== this.storedAndValidated) 
              console.log('stored by enter', this.storedAndValidated) // ! can be false // save to store
            if(!this.storedAndValidated)
              event.target.innerText = active_cell_content 
            this.compare_storedAndValidated = this.storedAndValidated
            active_cell.blur()
            return false
          }
        }
      }

      // * comment out this if you want for active cell to stay active when ENTER is pressed but content of cell is not changed
      table.onkeydown = (event) => {
        if(event.keyCode === 13 || event.keyCode == 27) {
          active_cell.blur()
        }
      }

      // saves to store if clicked outside of the table or on other cell of a table
      // ! click away inside a table should do the same trick as clicking outside of a table
      document.addEventListener('mousedown', (event) => {
        if(!event.target.closest('td')) {
          if(this.compare_storedAndValidated !== this.storedAndValidated) 
            console.log('stored by click:', this.storedAndValidated) // ? can be false // save to store
          if(active_cell !== null) {
            if(!this.storedAndValidated && active_cell.nodeName !== 'TABLE')
              active_cell.innerText = active_cell_content // resets initial cell content
            this.compare_storedAndValidated = this.storedAndValidated
            return false
          }
        } else {
          if(this.compare_storedAndValidated !== this.storedAndValidated) 
            console.log('stored by click', this.storedAndValidated) // ? can be false // save to store
          this.compare_storedAndValidated = this.storedAndValidated
          if(this.storedAndValidated === false) { // if forbiden chars entered
            // ! restore initial value
            console.log('script will be blocked. user must restore initial value')
          }
        }
      })
    },
    isScrollable(el) { 
          
      // scrollTop() method sets or returns the vertical scrollbar position for the selected elements
      let y1 = el.scrollTop
      el.scrollTop += 1
      let y2 = el.scrollTop
      el.scrollTop -= 1
      let y3 = el.scrollTop
      el.scrollTop = y1
    
      // scrollLeft() method returns the horizontal scrollbar position for the selected elements
      let x1 = el.scrollLeft
      el.scrollLeft += 1
      let x2 = el.scrollLeft
      el.scrollLeft -= 1
      let x3 = el.scrollLeft
      el.scrollLeft = x1

      // returns true or false accordingly 
      return { 
        horizontallyScrollable: x1 !== x2 || x2 !== x3,  
        verticallyScrollable: y1 !== y2 || y2 !== y3 
      } 
    }
  }
}
</script>

<style>
table {
  border-collapse: collapse;
  background: white;
}
table th {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  background-color: rgb(228, 228, 228);
  padding: 5px 10px;
  position: relative;
  text-align: left;
  white-space: nowrap;
}
table td {
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding: 15px 10px;
  text-align: right;
  /* max-width: fit-content; */
  /* max-width: 0; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.brdr-btm {
  border-bottom-color: transparent!important;
}
.left-shadow {
  box-shadow: 7px 1px 16px -5px rgba(0,0,0,0.64);
  -webkit-box-shadow: 7px 1px 16px -5px rgba(0,0,0,0.64);
  -moz-box-shadow: 7px 1px 16px -5px rgba(0,0,0,0.64);
}
.mv0 {
  max-width: 0;
}
tr:nth-child(odd) {
  background: rgb(245, 245, 245);
}
.resizer {
  /* Displayed at the right side of column */
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}
.resizer:hover,
.resizing {
  border-right: 3px solid rgb(135, 52, 245);
}
</style>