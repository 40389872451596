<template>
  <div id="app">
    <p style="margin-right: 10px">Passendo Magic Tables</p>
    <div class="ts_selector_wrap">
      <Hamburger class="ts_menu" @click.native="switcher = !switcher"/>
      <Selector class="ts_selector" :switcher="switcher" @reorder="key++" />
    </div>
    <vue-good-table
      style="position: relative"
      :columns="columns"
      :rows="rows"
      :sort-options="{
        enabled: true,
      }"
      :pagination-options="{
        enabled: true
      }"
      x_mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
    >
      <template slot="table-column" slot-scope="props">
        <!-- <span v-if="props.column.label == thName"> -->
        <span v-if="thName.includes(props.column.label)">
          {{props.column.label}}
          <!-- <img :src='images[sortIcon]' width='15' style="margin-left: 5px;"> -->
          <img :src='images[sorting[props.column.label]]' width='15' style="margin-left: 5px;">
        </span>
      </template>
    </vue-good-table>
    <Table :key="key" @hover-over="hideShowSortIcons" />
  </div>
</template>

<script>

/**
 * remove x_ from mode="remote"
 * 
 * in data comment
 *  all: true,
 *  and uncomment
 *  pagination_mode: true,
 * 
 * in mounted()
 *  comment
 *    this.test_data_load()
 *  uncomment
 *    this.loadItems()
 * 
 * in loadItems()
 *  comment
 *    console.log('server call...')
 *  uncomment
 *    // axios.post
 * 
 * in back end
 *  in $request = $data->request;
 *  comment
 *    $all = $data->all;
 *  uncomment
 *    // $pagination_mode = $data->pagination_mode;
 */

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import { EventBus } from './main'

import Table from  './components/TheTable'
import Selector from './components/TheSelector'
import Hamburger from './components/MenuIcon'

export default {
  name: 'App',
  components: {
    VueGoodTable,
    Selector,
    Table,
    Hamburger
  },
  data() {
    return {
      key: 0,
      switcher: false,
      columns: [],
      rows: [],
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        request: 'get_data',
        all: true,
        // pagination_mode: true,
        columnFilters: {
        },
        sort: {
          field: '', 
          type: '',
        },
        page: 1, 
        perPage: 10
      },
      images: {
        gray: require('../public/img/SI_GRAY.png'),
        asc: require('../public/img/SI_ASC.png'),
        desc: require('../public/img/SI_DESC.png')
      },
      // sortIcon: 'gray', // gray, asc, desc
      thName: [], // []
      sorting: {}
    }
  },
  mounted() {
    this.sorting = JSON.parse(localStorage.getItem('sorting'))
    // console.log(this.sorting)

    this.test_data_load()
    // this.loadItems()
    this.inlineEditEvent()

    // ! reset localStorage on refresh
    localStorage.removeItem('scrBar')
    localStorage.removeItem('scrollbarW')
    // localStorage.removeItem('thW')
  },
  methods: {
    hideShowSortIcons(thName) {
      if(thName == undefined) return
			// ! check if item exists then return
			if(!this.thName.includes(thName))
        this.thName.push(thName)
        
      // hide sorting icon after set amount of time
      setTimeout(() => {
        this.thName.map((i, index) => i === thName && this.thName.splice(index, 1))
      }, 3000)
    },

    // enable inline editing on cells
    inlineEditEvent() {
      EventBus.$emit('inlineEditEvent', true) // ! inline edit enable / disable
    },
    test_data_load() {
      axios.post('https://www.passendo.panov.rs/api/myTable.php', // {
      // axios.post('https://www.passendo.panov.rs/api/get_data.php', // {
      //   request: 'get_data',
      //   all: true,
      // }
        this.serverParams
      )
      .then(response => {
        this.columns = response.data.columns
        this.rows = response.data.rows
        EventBus.$emit('async-then')
      })
      .catch(err => {
        console.error(err)
      })
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
      // console.log(this.serverParams)
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage})
      this.loadItems()
      EventBus.$emit('verticalScroll', {rpp: params.currentPerPage, pp: true})
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage})
      this.loadItems()
      // console.log(params.currentPerPage)
      // console.log(this.rows)

      // ! key forces reload of cmp TheTable.vue as it needs resizer to recalculate height on pagination pageChange event
      // this.key++
      EventBus.$emit('verticalScroll', {rpp: params.currentPerPage, pp: false})
    },

    onSortChange(params) {

      // save sorting order to local storage
      const field = params[0].field
      const type = params[0].type // asc/desc

      // ! compare field with th.innerText and return actual th.innerText
      const field_removed_ = field.replace(/_/g, ' ') // removing the _ | ex: last_name -> last name
      const thText = this.thName.find(item => item.toLowerCase() == field_removed_) // return item as is | ex: Last Name
      this.sorting[thText] = type

      // const field_upperCaseWords = /(\b[a-z](?!\s))/g
      // const field_converted = field_removed_.replace(field_upperCaseWords, function(x){return x.toUpperCase()}); // console.log(field_converted)
      // this.sorting[field_converted] = type
      localStorage.setItem('sorting', JSON.stringify(this.sorting))

      this.updateParams({
        sort: [{
          type: params[0].type,
          field: params[0].field
          // type: params.sortType,
          // field: this.columns[params.columnIndex].field
        }],
      });
      this.loadItems()
      // EventBus.$emit('verticalScroll')
    },
    
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    loadItems() {
      console.log('server call...')
      // axios.post('https://www.passendo.panov.rs/api/get_data.php', this.serverParams)
      // .then(response => {
      //   console.log(response)
      //   this.columns = response.data.columns
      //   this.rows = response.data.rows
      //   this.totalRecords = response.data.totalRecords
      // })
      // .catch(err => {
      //   console.error(err)
      // })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
  color: #2c3e50;
  margin-top: 60px;
  border: 1px solid red;
  border-radius: 5px;
}
.ts_menu {
  cursor: pointer;
}
.ts_selector_wrap {
  position: relative;
  z-index: 1;
  margin-right: 10px;
}
.ts_selector {
  position: absolute;
  top: 85px; /* 35 */
  right: 15px; /* 7 */
  text-align: center;
  background: white;
}

.vgt-responsive::-webkit-scrollbar {
  height: 9px;
  width: 20px;
}

.vgt-responsive::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
.vgt-responsive::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

/* Handle on hover */
.vgt-responsive::-webkit-scrollbar-thumb:hover {
  background: #7837e6;
}

#app {
  font-family: "Rubik", sans-serif;
}
table.vgt-table {
  font-size: 12px;
  border-top: none;
  border-right: none;
  border-left: none;
}
tr:nth-child(odd) {
  background: linear-gradient(90deg, rgba(238,238,238,1) 90%, rgba(238,238,238,0) 100%) !important;
}
.vgt-table thead th {
  background: white !important;
  padding-right: 3px;
}
.vgt-table td:focus {
  outline: solid;
  outline-width: 2px;
  outline-offset: -3px;
  outline-color: #4690F9;
}
.vgt-table.bordered td,
.vgt-table.bordered th {
  border: none;
}
.vgt-table th.sortable:before,
.vgt-table th.sortable:after {
  /* visibility: attr(data-visibility); */
  /* visibility: hidden; */
  display: none;
}
.vgt-table span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
td.vgt-left-align {
  line-height: 30px;
}
th.vgt-left-align.sortable {
  line-height: 38px;
}
.vgt-wrap__footer {
  color: black;
  /* padding: 15px 10px 10px 10px; */
  background: white;
  font-size: 12px;
  border: unset;
  border-top: chocolate 1px solid;
  border-radius: 0 0 10px 10px;
}
.vgt-wrap__footer .footer__navigation__page-btn {
  text-decoration: none;
  color: #606266;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
}
.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid #606266;
}
.vgt-wrap__footer .footer__row-count__label {
  font-size: 12px;
}
.vgt-wrap__footer .footer__row-count__select {
  font-size: 12px;
}
</style>