<template>
  <div v-if="switcher" class="ts_wrapper">
    <div class="ts_header">
      <span>Columns setup</span>
    </div>
    <div class="drag-sort-enable">
      <div
        class="ts_column"
        @click="checkPropagate(index)"
        v-for="(column, index) in columns" 
        :key="index">
        <div class="ts_separator">
          <Checkbox
            ref="checkbox"
            class="ts_checkbox" 
            :value="column"
            v-model="checkBoxArr" 
            color="#8272E5"
            @change="handleChange(column, index)"
          />
          <span>{{column}}</span>
        </div>
        <Dragger class="ts_component" />
      </div>
    </div>
  </div>
</template>

<script>
import Dragger from './TheDragger'
import Checkbox from 'vue-material-checkbox'
import cloneHeader from '../mixins/cloneHeader'

import { EventBus } from '../main'
import stickyHeader from '../mixins/stickyHeader'

export default {
  components: {
    Dragger,
    Checkbox
  },
  props: [
    'switcher'
  ],
  data() {
    return {
      columns: [],
      columns_reordered: [],
      checkBoxArr: [],
      checkBoxInitiall: [],
      table: {},
      headerTHs: {},
      columnsTds: '',
      parentIndex: 0,
      ind: false,
      checkbox_indexes: [],
      checkbox_params: [],
      checkbox_names: [],
      columnTh: '',
      columnTds: '',
      position: [],
      reordered: [],
      hiddenArray: [],
      rowsPerPage: 10
    }
  },
  mounted() {
    let select = document.querySelector("#app > div.vgt-wrap > div > div.vgt-wrap__footer.vgt-clearfix > div.footer__row-count.vgt-pull-left > select")
    this.rowsPerPage = parseInt(select.value)

    EventBus.$on('async-then', () => {
      console.log('async then')
      setTimeout(() => {
        this.tableSetter("table")
      }, 0)
    })
    EventBus.$on('verticalScroll', (rpp) => {
      console.log('vertical scroll')
      this.reRender(rpp)
      // this.cloneHeader()
    })
  },
  updated() {
    this.enableDragSort('drag-sort-enable')
  },
   mixins: [
    stickyHeader('.vgt-table tr'),
    cloneHeader('th')
  ],
  methods: {
    reRender(rpp) {

      // goes through each TD in a newly rendered row and reorderes them by following dataset in TH
      setTimeout(() => {
        let order = []
        let rowCopy = []
        let th = this.table.querySelectorAll('th');
        [...th].map(item => order.push(item.dataset.order))
        let tableRows = document.querySelectorAll('tr');
        // [...tableRows].slice(1).map((tr, index) => { // ! slice here to remove cloned Header, only if position sticky
        /*eslint-disable*/
        [...tableRows].map((tr, index) => {
          if(index > this.rowsPerPage) {
            rowCopy = [...tr.children]
            order.map(num => {
              return tr.append(rowCopy[num-1])
            })
          }
        })
        if(rpp.pp) {
          [...tableRows].slice(1).map((tr, index) => {
            rowCopy = [...tr.children]
            order.map(num => {
              return tr.append(rowCopy[num-1])
            })
          })
        }
        this.rowsPerPage = rpp.rpp
      }, 0)

      // hides switched off columns on reload
      this.hiddenArray.map(obj => {
        this.hideShowColumn(obj.th, obj.td, false)
        setTimeout(() => {
          let th = [...this.table.querySelectorAll('th')].filter(el => el.textContent.includes(obj.th[0].innerText))
          let td = [...this.table.querySelectorAll(`td:nth-child(${th[0].cellIndex + 1})`)]
          this.hideShowColumn(th, td, true)
        }, 0);
      })
    },

    // read tables THs and push them to columns
    tableSetter(element) {
      this.table = document.querySelector(element)
      this.headerTHs = this.table.querySelectorAll('th')
      this.columnsTds = this.table.querySelectorAll('td')

      Array.from(this.headerTHs).map((th) => {

        // adding th inner text to array that lists names
        this.columns.push(th.innerText)

        // adding th inner text to array to recognize checked columns
        this.checkBoxArr.push(th.innerText)
        this.checkBoxInitiall.push(th.innerText)
      })

      // set position array | first element is an object example: {1: FirstName, ...} containing the initial state
      let initState = {}
      this.columns.map((sel, i) => {
        Object.assign(initState, { [i + 1]: sel })
      })
      this.position.push(initState)
    },

    /* eslint-disable */
    handleChange(column, index) {
      this.checkbox_params = [column, index]

      // this returns an empty array if any single checkbox is deselected, and returns a single item - column name, if some checkbox gets selected
      let columnName = this.checkBoxArr.filter(name => name == column)
      if(columnName.length) {

        // if all checkboxes are ticked empty checkbox_indexes array
        if(this.checkBoxArr.length == this.columns.length) {
          this.checkbox_indexes.length = 0
          this.checkbox_names.length = 0
        }

        // * remove unchecked indexes from checkbox_indexes array
        this.checkbox_indexes.splice(this.checkbox_indexes.indexOf(index), 1)
        this.checkbox_names.splice(this.checkbox_names.indexOf(column), 1)
      } else {
        
        // push indexes of checked columns to checkbox_indexes array
        this.checkbox_indexes.push(index)
        this.checkbox_names.push(column)
      }

      // select TH by column name on selected checkbox
      this.selectColumnData(column)
      if(this.checkBoxArr.length != this.columns.length) {

        if(this.hiddenArray.length) {

          // check if same object exists in array
          let exists = false
          this.hiddenArray.map(obj => {

            // values are stored by reference
            // so when a column is moved, stored cellIndex gets updated
            if(obj.th[0].cellIndex == this.columnTh[0].cellIndex)
              exists = true
          })
          if(!exists) {
            this.hiddenArray.push({th: this.columnTh, td: this.columnTds})
          }
        } else {
          this.hiddenArray.push({th: this.columnTh, td: this.columnTds})
        }
        this.hideShowColumn(this.columnTh, this.columnTds, true) // unchecked | hide it
      }
      if(columnName.length) {
        this.selectColumnData(column)
        this.hiddenArray = this.hiddenArray.filter(obj => obj.th[0].cellIndex !== this.columnTh[0].cellIndex)
        this.hideShowColumn(this.columnTh, this.columnTds, false)
      }

      // if all checkboxes are ticked
      if(this.checkBoxArr.length === this.checkBoxInitiall.length)
        EventBus.$emit('re-render', true)
      else
        EventBus.$emit('re-render', false)
        this.cloneHeader()
        this.stickyHeader()
        EventBus.$emit('first_clmn')
    },
    selectColumnData(column) {
      this.columnTh = [...this.table.querySelectorAll('th')].filter(el => el.textContent.includes(column))
      this.columnTds = this.table.querySelectorAll(`td:nth-child(${this.columnTh[0].cellIndex + 1})`)
    },
    hideShowColumn(th, td, bool) {
      [...th].map(el => el.hidden = bool);
      [...td].map(el => el.hidden = bool)
    },
    enableDragSort(listClass) {
      const sortableLists = document.getElementsByClassName(listClass);
      [...sortableLists].map(list => {
        this.enableDragList(list)
      })
    },
    enableDragList(list) {
      [...list.children].map(item => {
        this.enableDragItem(item)
      })

      // todo trying to influence on enabling dragging while mouse over span text
      let span = document.querySelectorAll('.ts_separator'); // * NodeList of divs with a class of .ts_separator
      // [...span].map(sel => sel.parentNode.addEventListener('mousedown', this.pointerSwitch)); // * .ts_separator div should have style = pointer-events: none
      [...span].map(sel => sel.style.pointerEvents = 'none'); // * .ts_separator div should have style = pointer-events: none
      [...span].map(sel => sel.firstChild.style.pointerEvents = 'all') // * first child should have style = pointer-events: all
    },
    pointerSwitch(e) {
        // if(e.target.className == 'ts_column') // clicking on a parent div
        // console.log(e.target.closest('.ts_separator'))
    },
    enableDragItem(item) {
      item.setAttribute('draggable', true)
      item.ondrag = this.handleDrag
      item.ondragend = this.handleDrop
    },
    handleDrag(item) {
      
      // clicked modal row
      const selectedItem = item.target,
      
      // clicked modal row's parent
      list = selectedItem.parentNode,
      
      // mouse coordinates
      x = event.clientX,
      y = event.clientY
      
      let columnTh = this.table.querySelectorAll('TH')
      let selectedItemTH = [...columnTh].filter(th => th.innerText == selectedItem.innerText)[0]
      let selectedItemTDs = this.table.querySelectorAll(`td:nth-child(${selectedItemTH.cellIndex + 1})`)

      selectedItem.classList.add('drag-sort-active')

      let swapItem = document.elementFromPoint(x, y) === null ? selectedItem : document.elementFromPoint(x, y);
      let swapItemTH = [...columnTh].filter(th => th.innerText == swapItem.innerText)[0]
      let swapItemTds = null
      if(swapItemTH) {
        swapItemTds = this.table.querySelectorAll(`td:nth-child(${swapItemTH.cellIndex + 1})`)
      }
      if (list === swapItem.parentNode) {
        // * swapItem = swapItem !== selectedItem.nextSibling ? swapItem : swapItem.nextSibling // <- base example
        if(swapItem !== selectedItem.nextSibling) {
          swapItem = swapItem
          swapItemTH = swapItemTH
          swapItemTds = swapItemTds
        } else {
          swapItem = swapItem.nextSibling
          swapItemTH = swapItemTH.nextSibling
          if(swapItemTH) {
            swapItemTds = this.table.querySelectorAll(`td:nth-child(${swapItemTH.cellIndex + 1})`)
          }
        }
        list.insertBefore(selectedItem, swapItem)
        this.table.childNodes[0].children[0].insertBefore(selectedItemTH, swapItemTH);
        // ! last in order needs to be moved twice to accept a change
        [...selectedItemTDs].map((item, index) => item.parentNode.insertBefore(item, swapItemTds[index]))
        this.cloneHeader() // reacts immediately on columns switching // ! messes with REORDERING!!!
        this.stickyHeader()
      }
    },
    handleDrop(event) {
      // this.cloneHeader() // reacts postponed on columns switching
      event.target.classList.remove('drag-sort-active');

      // ! POSITIONING
      let changedState = {}
      this.reordered = []
      let names = event.target.parentNode.children
      let pos = Array.from(names).indexOf(event.target) + 1;
      [...names].map((sel, i) => {
        Object.assign(changedState, { [i + 1]: sel.innerText })
        this.reordered.push(sel.innerText)
        // this.reordered_clmns.push()
      })
      if (this.position.length == 2)
        this.position.pop()
      this.position.push(changedState)

      // emiting an event to force reload of The Table component, as it has to set resizer div on table THs
      this.$emit('reorder')
      EventBus.$emit('first_clmn')
      this.columns_reordered = [...names].map(i => i.innerText)
    },

    // todo ||... click elswhere and stil check / uncheck
    // * label could be wider and then clicking anywhere except on 6 dots would check / uncheck
    // * 6 dots would be reserved only for reordering
    // * this checkPropagate() method is not the right approach... to be tested
    checkPropagate(index) {
      // this.$refs.checkbox[index].$el.childNodes[0].childNodes[1].childNodes[0].click()
      // console.log(this.$refs.checkbox[index].$el.childNodes[1]) // label
    }
  },
  watch: {
    switcher() {
      if(this.columns_reordered.length)
        this.columns = this.columns_reordered
    }
  }
}
</script>

<style scoped>
.ts_wrapper {
  padding: 10px;
  width: fit-content;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(128,128,128,1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(128,128,128,1);
  box-shadow: 0px 0px 10px 0px rgba(128,128,128,1);
}
.ts_header {
  margin: 10px 0;
}
.ts_component {
  margin-right: 10px;
}
.ts_column {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ts_column:hover {
  background: whitesmoke;
  transition: background 0.15s;
}
.ts_column input {
  margin: 10px;
}
.ts_separator {
  display: flex;
  align-items: center;
}
.ts_checkbox {
  width: fit-content;
}
.m-chckbox--container {
  margin: 10px 0 10px 10px;
}
.drag-sort-active {
  background: transparent;
  color: transparent;
  border: 1px solid rgb(135, 52, 245);
}
</style>