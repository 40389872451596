<template>
	<div class="mi_wrapper">
		<div class="mi_menu" v-for="(n, i) in 3" :key="i">
		</div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.mi_wrapper {
    display: inline-block;
    margin-bottom: 5px;
}
.mi_menu {
    width: 20px;
    height: 4px;
    margin-bottom: 2px;
    background: black;
    border-radius: 3px;
}
</style>