<template>
	<div class="td_wrapper">
		<div class="td_dragg" v-for="(n, i) in 3" :key="i">
			<div class="td_circle"></div>
			<div class="td_circle"></div>
		</div>
</div>
</template>

<script>
export default {

}
</script>

<style>
.td_dragg {
	width: fit-content;
	display: flex;
}
.td_circle {
	width: 3px;
	height: 3px;
	border-radius: 3px;
	margin: 1px;
	background: #AEB1B7;
}
</style>