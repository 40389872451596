// // standard way of creating a mixin, no params
// export default {
//   methods: {
//     stickyHeader() {
//       // do something...
//     }
//   }
// }

// * returns original header row positon on scroll

// returning a function, so params can be accepted
/*eslint-disable*/
const sticky = (header) => ({
  methods: {
    stickyHeader() {
      let origHeader = document.querySelector(header)
      let cloneHeader = document.querySelector('.sticky-table-header')
      let bounding = origHeader.getBoundingClientRect()
      if(bounding.top <= 0) {
        cloneHeader.parentElement.style.display = 'table-row'
      } else {
        cloneHeader.parentElement.style.display = 'none'
      }

      // bounding.top <= 0 && this.translate(origHeader, bounding)

      // document.addEventListener('scroll', (e) => {
      //   console.dir(e.target.scrollingElement.scrollTop)
      // })
    },
    // translate(origHeader, bounding) {
    //   bounding = Math.abs(bounding.top)
    //   origHeader.parentNode.style.transform = 'translateY(' + bounding + 'px)'
    // }
  }
})
export default sticky