const clone = (header) => ({
  methods: {
    cloneHeader() {

      // delete previously created (if exists)
      const topTable = document.querySelector('.sticky-table-top')
      const topHeader = document.querySelector('.sticky-table-header')
      if(topTable && topHeader) {
        topHeader.remove()
        topTable.remove()
      }

      // create table and table row
      let tableClone = document.createElement('table')
      let headerClone = document.createElement('tr')

      // try to append to #app
      const app = document.querySelector('#app')
      app.appendChild(tableClone)  // ! change this
      // app.insertBefore(tableClone, app.firstChild)   // ! change this
      tableClone.appendChild(headerClone)
      tableClone.classList.add('sticky-table-top')
      headerClone.classList.add('sticky-table-header');

      // position table to top
      tableClone.style.position = 'fixed' // ! change this absolute / fixed
      tableClone.style.top = 0  // ! change this
      tableClone.style.setProperty('z-index', '100')
      // tableClone.style.setProperty('position', 'sticky')   // ! change this
      // tableClone.style.setProperty('top', '0')   // ! change this

      // select header of existing table
      const headerElement = document.querySelectorAll(header) // 'ths'
      const newTopHeader = document.querySelector('.sticky-table-header'); // 'tr'

      // clone node
      [...headerElement].map(cell => {
        let node = cell.cloneNode(true)
        newTopHeader.appendChild(node)
        let cs = getComputedStyle(cell)
        node.style.width = cs.width
        node.style.border = cs.border
        node.style.cursor = cs.cursor
        node.style.background = cs.background
        node.style.color = cs.color
        node.style.fontSize = cs.fontSize
        node.style.padding = cs.padding
      });

      // remove resizer child
      [...newTopHeader.children].map(res => res.children[1].remove())

      // * setting body overflow to hidden
      document.body.style.overflowX = 'hidden'

      // top header reacts on check / uncheck in setup, and also to horizontal scroll event
      const appFrame = document.querySelector('.vgt-responsive')
      appFrame.addEventListener('scroll', (e) => {
        /*eslint-disable*/
        let scrollNum = e.target.scrollLeft
        // ! watchout for body margin (8px)
        scrollNum = scrollNum == 0 ? 0 : -Math.abs(e.target.scrollLeft) // left position is equal to negative scrollLeft
        if(newTopHeader.parentNode) {
          // ! on horizontal scroll, resizing is experiencing some issues (on resizer click event, new header ignores position left property, and resets to default)
          // newTopHeader.parentNode.style.left = scrollNum + 'px' // this only works with position: absolute
          [...newTopHeader.children].slice(1).map(item => item.style.transform = 'translateX(' + scrollNum + 'px)') // this works regardless of position: absolute or sticky
          // newTopHeader.parentNode.style.transform = 'translateX(' + scrollNum + 'px)' 
        }
      })
    }
  }
})
export default clone